var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"posReferenceSizeComponent"},[_c('div',{staticClass:"content"},[_c('ReferenceCanvas',{ref:"referenceCanvas",attrs:{"forms":_vm.forms,"curIndex":_vm.curIndex},on:{"update:forms":function($event){_vm.forms=$event},"update:curIndex":function($event){_vm.curIndex=$event},"update:cur-index":function($event){_vm.curIndex=$event}}}),_c('BaseForm',{ref:"form",attrs:{"customClass":"customClass","label-width":"120px","cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"nameSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":placeholder},on:{"change":_vm.handleNameChange},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"base_group_coverSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"files":_vm.form[prop],"limit":1},on:{"update:files":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}},{key:"base_group_markSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"files":_vm.form[prop],"limit":1},on:{"update:files":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}}])})],1),_c('div',{staticClass:"bottom"},[_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.back}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }