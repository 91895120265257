<template>
  <div class="imgUpLoadComponent">
    <draggable :list="temFilesList" v-bind="dragOption" class="list" @change="dragChange">
      <div
        v-for="(file, indx) in temFilesList || []"
        v-loading="loading"
        :class="['item', file.uploadStatus === 'success' && 'success-item']"
        :style="{
          width: $attrs.size || '148px',
          height: $attrs.size || '148px',
          'line-height': $attrs.size || '148px'
        }"
        :key="file.uid || file.id"
      >
        <div class="card-wrapper">
          <div class="pic-name" v-if="showFileName">
            {{ fileName(file) }}
          </div>
          <label
            v-if="file.uploadStatus"
            :class="['el-upload-list__item-status-label', file.uploadStatus === 'fail' && 'error']"
          >
            <i class="el-icon-upload-success el-icon-check" v-if="file.uploadStatus === 'success'"></i>
            <i class="el-icon-upload-success el-icon-warning-outline" v-else-if="file.uploadStatus === 'fail'"> </i>
          </label>

          <img
            ref="img"
            style="width: 148px; height: 148px; object-fit: contain"
            @load="loadedHandler(indx, file)"
            @error="onerror(file)"
            :src="srcList[file.$srcKey]"
          />

          <div class="dialog" v-if="!hideDialog">
            <i class="el-icon-delete" @click="deleteHandler(file, indx)"></i>
          </div>
        </div>
      </div>
      <div
        v-if="showUploadBtn"
        class="upload-item"
        :style="{
          width: $attrs.size || '148px',
          height: $attrs.size || '148px',
          'line-height': $attrs.size || '148px'
        }"
      >
        <el-upload
          :http-request="requestHandler"
          :file-list="files"
          :show-file-list="false"
          :action="$attrs.action || '#'"
          :list-type="$attrs['list-type'] || 'picture-card'"
          :accept="accept"
          style="width: 100%; height: 100%"
          :before-upload="$attrs['before-upload'] || beforeUpload"
          :multiple="$attrs.multiple === undefined ? false : $attrs.multiple"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <i slot="default" class="el-icon-plus"></i>
          <template #tip>
            <slot name="tip"/>
          </template>
        </el-upload>
      </div>
    </draggable>
  </div>
</template>
<script>
import emitter from 'element-ui/src/mixins/emitter'
const SIZE = 150 //图片大小限制
import config from '@/service/config'

const serverRootPath = config.baseURL
import { getThumbnail, getPicName, getFileSuffix, parseImgSrc } from '@/utils'
//图片上传到oss的uuid和原本文件名称的分隔符
import { OSS_SEPARATOR } from '@/utils/constant'

import draggable from 'vuedraggable'
import { validatenull } from '@/components/avue/utils/validate';
// draggable 配置：https://segmentfault.com/a/1190000021376720
export default {
  components: {
    draggable
  },
  mixins: [emitter],
  props: {
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg'
    },
    showFileName: {
      type: Boolean,
      default: true
    },
    files: {
      type: Array,
      default: () => []
    },
    title: String, //更改为前端上传图片后，图片名称都为随机字符串，传入title显示真实的图片名称
    loading: {
      type: Boolean,
      default: false
    },

    //获取属性
    prop: {
      type: String
    },

    //是否需要获取图片的尺寸
    needPicSIze: {
      type: Boolean,
      default: false
    },

    hideDialog: Boolean,
    hiddenUploadBtn: Boolean,
    validateEvent: Boolean
  },
  data() {
    return {
      temFilesList: [],
      srcList: {}
    }
  },
  watch: {
    files: [
      {
        handler(newVal) {
          // console.log(newVal.map(({ uid }) => uid), newVal, this.srcList)
          this.temFilesList = newVal
          let nSrcList = {}
          newVal.map((file) => {
            let url = file.url || file.path
            let srcKey = file.uid || file.id
            file.$srcKey = srcKey
            if (url) {
              if (!/^http/.test(url)) {
                nSrcList[srcKey] = url
              }
              return (nSrcList[srcKey] = url)
            }
            getThumbnail(file).then((res) => {
              this.$set(nSrcList, srcKey, res.url)
            })
          })
          // console.log(nSrcList)
          this.srcList = nSrcList
        },
        deep: true,
        immediate: true
      },
      function (newVal, oldValue) {
        this.onceValidateEventLock = false
        if(validatenull(oldValue) && validatenull(newVal) && newVal != oldValue && !this.onceValidateEventLock) {
          return
        }
        this.onceValidateEventLock = true
        if (!this.validateEvent) return
        this.dispatch('ElFormItem', 'el.form.change', [newVal])
      }
    ]
  },
  filters: {
    //格式化图片地址
    formatRowUrl(file) {
      let url = file.url || file.path
      if (url) {
        if (!/^http/.test(url)) {
          return `${serverRootPath}${url}`
        }
        return url
      }
      return URL.createObjectURL(file)
    }
  },
  computed: {
    showUploadBtn() {
      if (this.hiddenUploadBtn) {
        return false
      }
      if (!this.$attrs.limit) {
        return true
      }
      return this.$attrs.limit > this.files.length
    },
    fileName() {
      return (file) => {
        //存在title 返回title，title的权重高于file.name
        if (this.title) {
          return this.title
        }
        if (file.name) {
          return file.name
        }
        if (file.path || file.url) {
          let fileName
          try {
            fileName = getPicName(decodeURIComponent(file.url || file.path || ''))
          } catch (e) {

          }
          try {
            const suffix = getFileSuffix(file.url || file.path || '')
            return fileName.split(OSS_SEPARATOR)[0].replace(`.${suffix}`, '') + `.${suffix}`
          } catch (err) {
            return fileName
          }
        }
        return '暂无名称'
      }
    },
    dragOption() {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
        draggable: '.item',
        disabled: this.$attrs.dragDisabled,
        ...this.$attrs
      }
    }
  },
  methods: {
    loadedHandler(index, file) {
      const src = this.srcList[file.uid || file.id]
      // console.log('src', src)
      const img = new Image()
      img.onload = () => {
        if (this.needPicSIze) {
          const { width, height } = img
          this.files[index].width = width
          this.files[index].height = height
          this.files[index].prop = this.prop
        }
      }
      img.src = src
    },
    //检查图片类型和尺寸
    beforeUpload(file) {
      let typeArr = this.accept
      if (typeof typeArr === 'string') {
        typeArr = typeArr.split(/[,，]\s*/)
      }
      const includeType = typeArr.includes(file.type)
      const size = this.$attrs.PIC_SIZE || SIZE
      const isMoreSize = file.size / 1024 / 1024 < size
      if (!includeType) {
        this.$message.error(`上传图片只能是 ${typeArr.join(',')} 格式!（请确保后缀是小写）`)
      }
      if (!isMoreSize) {
        this.$message.error(`上传图片大小不能超过 ${size}MB!`)
      }
      return includeType && isMoreSize
    },
    picLoadHandler(file) {
      URL.revokeObjectURL(file.url)
    },
    //上传成功状态 'success', 失败状态'fail'
    toggleUploadStatus(status) {
      this.temFilesList.map((item) => (item.uploadStatus = status))
      this.$emit('update:files', this.temFilesList)
    },
    //删除单个接口
    deleteHandler(file, index) {
      if (!file.uploadStatus) {
        this.temFilesList.splice(index, 1)
        this.$emit('update:files', this.temFilesList)
      }
      this.$emit('deleteUploadImgHandler', {
        file,
        index
      })
    },
    //自定义上传
    requestHandler(info) {
      const { action, file } = info
      this.temFilesList.push(file)
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('update:files', this.temFilesList)
        this.$emit('updateFiles', this.temFilesList)
      }, 100)
    },

    dragChange(e) {
      this.$emit('dragChange', e)
      this.$emit('update:files', this.temFilesList)
    },

    onerror(file) {
      if (!file.$url) {
        let url = parseImgSrc(this.srcList[file.$srcKey])
        this.srcList[file.$srcKey] = file.$url = file.url = url
      }
      console.log(file)
    }
  }
}
</script>
<style lang="scss" scoped>
.imgUpLoadComponent {
  .list {
    display: flex;
    flex-wrap: wrap;
    .item,
    .upload-item {
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid $border-color;
      border-radius: 4px;
      .card-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .el-upload-list__item-status-label {
          display: block;
          position: absolute;
          right: -17px;
          top: -7px;
          width: 46px;
          height: 26px;
          background: #13ce66;
          text-align: center;
          transform: rotate(45deg);
          box-shadow: 0 1px 1px #ccc;
          line-height: 40px;
          z-index: 10;
          i {
            font-size: 12px;
            margin-top: 12px;
            transform: rotate(-45deg);
            font-weight: 600;
          }
          .el-icon-check,
          .el-icon-warning-outline {
            display: inline-block;
            width: 20px;
            text-align: right;
            color: #fff;
          }
        }
        .error {
          background: $color-danger;
        }
        .dialog {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.5);
          i {
            font-size: 30px;
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .pic-name {
        position: absolute;
        height: 1rem;
        width: 100%;
        line-height: 1rem;
        z-index: 100;
        left: 0;
        bottom: 0;
        font-size: 12px;
        background: $color-primary;
        color: #fff;
        text-align: center;
        @include overflow;
        border-radius: 4px 4px 0 0;
      }
      .card-wrapper:hover {
        .dialog {
          display: block !important;
        }
        .success-dialog {
          display: none !important;
        }
      }
      ::v-deep {
        .el-loading-spinner {
          line-height: 1;
        }
        .el-upload {
          width: 100%;
          height: 100%;
          line-height: inherit;
          .el-icon-plus {
            line-height: inherit;
          }
        }
      }

      .el-upload.el-upload,
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .upload-item {
      border: none;
    }
    //上传成功，隐藏上传按钮
    .success-item + .upload-item {
      display: none;
    }
  }
}
</style>
