var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"imgUpLoadComponent"},[_c('draggable',_vm._b({staticClass:"list",attrs:{"list":_vm.temFilesList},on:{"change":_vm.dragChange}},'draggable',_vm.dragOption,false),[_vm._l((_vm.temFilesList || []),function(file,indx){return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:file.uid || file.id,class:['item', file.uploadStatus === 'success' && 'success-item'],style:({
        width: _vm.$attrs.size || '148px',
        height: _vm.$attrs.size || '148px',
        'line-height': _vm.$attrs.size || '148px'
      })},[_c('div',{staticClass:"card-wrapper"},[(_vm.showFileName)?_c('div',{staticClass:"pic-name"},[_vm._v(" "+_vm._s(_vm.fileName(file))+" ")]):_vm._e(),(file.uploadStatus)?_c('label',{class:['el-upload-list__item-status-label', file.uploadStatus === 'fail' && 'error']},[(file.uploadStatus === 'success')?_c('i',{staticClass:"el-icon-upload-success el-icon-check"}):(file.uploadStatus === 'fail')?_c('i',{staticClass:"el-icon-upload-success el-icon-warning-outline"}):_vm._e()]):_vm._e(),_c('img',{ref:"img",refInFor:true,staticStyle:{"width":"148px","height":"148px","object-fit":"contain"},attrs:{"src":_vm.srcList[file.$srcKey]},on:{"load":function($event){return _vm.loadedHandler(indx, file)},"error":function($event){return _vm.onerror(file)}}}),(!_vm.hideDialog)?_c('div',{staticClass:"dialog"},[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.deleteHandler(file, indx)}}})]):_vm._e()])])}),(_vm.showUploadBtn)?_c('div',{staticClass:"upload-item",style:({
        width: _vm.$attrs.size || '148px',
        height: _vm.$attrs.size || '148px',
        'line-height': _vm.$attrs.size || '148px'
      })},[_c('el-upload',_vm._g(_vm._b({staticStyle:{"width":"100%","height":"100%"},attrs:{"http-request":_vm.requestHandler,"file-list":_vm.files,"show-file-list":false,"action":_vm.$attrs.action || '#',"list-type":_vm.$attrs['list-type'] || 'picture-card',"accept":_vm.accept,"before-upload":_vm.$attrs['before-upload'] || _vm.beforeUpload,"multiple":_vm.$attrs.multiple === undefined ? false : _vm.$attrs.multiple},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_vm._t("tip")]},proxy:true}],null,true)},'el-upload',_vm.$attrs,false),_vm.$listeners),[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }