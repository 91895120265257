var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referenceCanvas"},[_c('div',{staticClass:"doc"},[_c('el-alert',{attrs:{"title":"数字序号为前端显示顺序，拖动可调整顺序！","type":"warning","show-icon":""}}),_c('draggable',_vm._b({staticClass:"list",on:{"change":function($event){return _vm.dragChange($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},'draggable',_vm.dragOption,false),_vm._l((_vm.forms),function(ref,index){
var img_url = ref.img_url;
var show_rotate = ref.show_rotate;
var name = ref.name;
var base_group_cover = ref.base_group_cover;
var base_group_mark = ref.base_group_mark;
var text = ref.text;
return _c('div',{key:index,class:['item', 'drag-item', _vm.isSet({ name: name, base_group_cover: base_group_cover, base_group_mark: base_group_mark }) && 'is-set']},[_c('div',{class:['index-item', index == _vm.curIndex && 'active']},[_c('span',{staticClass:"num"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('div',{class:['img-wrapper', index == _vm.curIndex && 'active']},[_c('el-image',{style:({ transform: ("rotate(" + show_rotate + "deg)") }),attrs:{"fix":"contain","src":img_url},on:{"click":function($event){$event.stopPropagation();return _vm.groupClick(index)}}})],1),_c('div',{staticClass:"name-wrapper"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"el-icon-s-fold"})])])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }